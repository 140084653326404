import { ProductModel } from "./ProductModel";

export default class ProdutoTesteModel {
    constructor(id, productCode, is5Wire, hasShortDpDm, hasResistor, product) {
        this.id = id;
        this.productCode = productCode;
        this.is5Wire = is5Wire;
        this.hasShortDpDm = hasShortDpDm;
        this.hasResistor = hasResistor
        this.product = product;
    }

    static fromJson(json) {
        return new ProdutoTesteModel(
            json.id,
            json.produto_cod,
            json.vias == 0 ? true : false,
            json.curto == 1 ? true : false,
            json.resistor == 1 ? true : false,
            ProductModel.fromJson(json.produto)
        );
    }
}
