<template>
    <aside>
        <div class="titulo margem efeito">
            <div class="m-icone">
                <a
                    href="#"
                    class="icone-menu"
                    title="Menu"
                    onclick="document.getElementById('main').classList.toggle('esconde');return false"
                ></a>
            </div>
        </div>
        <div class="separador margem linha">
            <div class="m-icone">
                <router-link to="/" class="icone-smartphone" active-class="ativo" title="Monitoramento"></router-link>
                <router-link
                    to="/testes/cadastro-parametros"
                    class="icone-labs"
                    :class="!isActiveRoute('home') ? 'ativo' : ''"
                    active-class="ativo"
                    title="Cadastro de Parâmetros"
                ></router-link>
            </div>
        </div>

        <nav class="nav-maior separador">
            <div class="separador" v-if="isActiveRoute('home')">
                <router-link to="/" active-class="ativo">Monitoramento</router-link>
            </div>
            <div class="separador" v-if="isActiveRoute('cadastroParametro') || isActiveRoute('visualizacaoTestes')">
                <router-link to="/testes/cadastro-parametros" active-class="ativo"
                    >Cadastrar parâmetros de teste</router-link
                >
                <router-link to="/testes/visualizacao-testes" active-class="ativo">Visualização de testes</router-link>
            </div>
        </nav>
    </aside>
</template>

<script>
export default {
    methods: {
        isActiveRoute(routeName) {
            return this.$route.name === routeName;
        },
    },
};
</script>
