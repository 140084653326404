<template>
  <Line :options="chartOptions" :data="chartDataProp" />
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

export default {
  components: { Line },
  data() {
    return {
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: "index",
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            display: this.displayLegend,
            align: this.legendAlignment,
          },
        },
      },
    };
  },
  props: {
    chartDataProp: {
      type: Object,
      required: true,
    },
    legendAlignment: {
      type: String,
      required: false,
      default: "center",
    },
    displayLegend: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
