import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import PainelDispositivoView from "@/views/PainelDispositivoView.vue";
import { sso } from "roboflex-thalamus-sso-lib";
import { getPermissao } from "@/services/permissaoService";
import CadastroParametroTesteView from "@/views/CadastroParametroTesteView.vue";
import VisualizacaoTestesView from "@/views/VisualizacaoTestesView.vue";

export class RoutesName {
    static home = "home";
    static painelDispositivo = "painelDispositivo";
    static cadastroParametro = "cadastroParametro";
    static visualizacaoTestes = "visualizacaoTestes";
}

function sendUserToPortal() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://portal.thalamus.ind.br/#/";
  } else {
    window.location.href = "http://portal-homologacao.thalamus.ind.br/#/";
  }
}

// valida se esta logado
async function guardMyroute(to, from, next) {
    if (sso.validarSessao() && await hasPermission()) {
        next();
    } else {
        sendUserToPortal();
    }
}

async function hasPermission(){
    const permissoes = await getPermissao();
   // Id da funcionalidade necessária pra acessar o módulo como um todo
    const funcionalidadeNecessaria = 64;
    console.log(permissoes)
    return permissoes.includes(funcionalidadeNecessaria);
}

// valida se tem permissao
// async function guardPermissao(to, from, next, redirectToLogin = false) {
//     const permissoes = await getPermissao();
//     const funcionalidadeNecessaria = to.meta.funcionalidadeId;
//     if (funcionalidadeNecessaria && !permissoes.includes(funcionalidadeNecessaria)) {
//         if (redirectToLogin) {
//             sendUserToPortal();
//         } else {
//             next({ name: "home" });
//         }
//     } else {
//         next();
//     }
// }

const routes = [
    {
        path: "/",
        name: RoutesName.home,
        component: HomeView,
    },
    {
        path: "/painelDispositivo",
        name: RoutesName.painelDispositivo,
        component: PainelDispositivoView,
    },
    {
        path: "/testes",
        children: [

            {
                path: "cadastro-parametros",
                name: RoutesName.cadastroParametro,
                component: CadastroParametroTesteView,
            },
            {
                path: "visualizacao-testes",
                name: RoutesName.visualizacaoTestes,
                component: VisualizacaoTestesView,
            },
        ],
    },
];

const router = createRouter({
    mode: "history",
    history: createWebHistory(),
    routes,
});

router.beforeEach(guardMyroute);

export default router;
