import { api } from "roboflex-thalamus-request-handler/dist";
import { AccelerometerModel } from "../models/AccelerometerModel";

export default class AccelerometerDataSource {
  async getAccData(deviceId, dtParaBusca) {

    const response = await api.post(
      `monitoramento/acelerometro/buscar/${deviceId}`,
      dtParaBusca
    );

    const accData = [];
    if (response.data[0] === undefined) {
      return accData;
    }
    response.data[0].registros.forEach(addToList);
    function addToList(data) {
      accData.push(AccelerometerModel.fromJson(data));
    }
    return accData;
  }
}
