<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>Visualização de testes</h2>
            </div>
        </div>
        <div class="margem container">
            <div class="bloco margem">
                <div class="margem container">
                    <h3>Selecione a OP</h3>
                    <select @change="getOpData($event.target.value)">
                        <option value="" selected></option>
                        <option v-for="ele in ops" :key="ele.id" :value="ele.id">
                            {{ ele.opNum }}
                        </option>
                    </select>
                </div>
                <h2>{{ injectionOrder.productCode }} - {{ injectionOrder.productName }}</h2>
                <div class="grid-4 margem">
                    <div class="bloco margem">
                        <div class="fonte-fraca">Data de entrega</div>
                        <h3>
                            {{ parseDate(injectionOrder.deliveryDate) }}
                        </h3>
                    </div>
                    <div class="bloco margem">
                        <div class="fonte-fraca">Total de testes:</div>
                        <h3>
                            {{ opTestData.testsDone ?? "-" }}
                        </h3>
                    </div>
                    <div class="bloco margem">
                        <div class="fonte-fraca">Testes aprovados</div>
                        <h3>
                            {{ opTestData.succesfulTests ?? "-" }}
                        </h3>
                    </div>
                    <div class="bloco margem">
                        <div class="fonte-fraca">Testes reprovados</div>
                        <h3>
                            {{ opTestData.failedTests ?? "-" }}
                        </h3>
                    </div>
                </div>
            </div>

            <div class="bloco margem" v-if="sessionData == null">
                <div class="titulo">
                    <div class="margem container">
                        <h2>Sessões de teste</h2>
                    </div>
                </div>
                <table class="tabela">
                    <tbody>
                        <tr>
                            <th>Tester</th>
                            <th>Total</th>
                            <th>Aprovados</th>
                            <th>Reprovados</th>
                            <th>Inicio</th>
                            <th>Fim</th>
                        </tr>
                        <tr
                            v-for="ele of opTestData.sessions"
                            v-bind:key="ele.id"
                            v-on:click="getGeneralResultForSession(ele.id)"
                        >
                            <td>{{ ele.tester.name }}</td>
                            <td>{{ ele.testsDone }}</td>
                            <td>{{ ele.succesfulTests }}</td>
                            <td>{{ ele.failedTests }}</td>
                            <td>{{ parseDate(ele.startDt, true) }}</td>
                            <td>{{ parseDate(ele.endDt, true) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="bloco margem" v-if="sessionData != null">
                <div class="titulo">
                    <div class="margem container">
                        <div class="linha">
                            <i class="icone-voltar clicavel" v-on:click="clearSessionData()"></i>
                            <h2>Resultados da sessão</h2>
                        </div>
                    </div>
                </div>

                <div class="grid-3 margem">
                    <h3>Total de testes: {{ sessionData.testsDone }}</h3>
                    <h3>Testes aprovados: {{ sessionData.succesfulTests }}</h3>
                    <h3>Testes reprovados: {{ sessionData.failedTests }}</h3>
                </div>

                <div class="margem">
                    <div class="grid-3">
                        <div class="bloco margem">
                            <div class="alinha-centro titulo fonte-maior">VCC</div>
                            <div class="linha" style="justify-content: space-evenly">
                                <div class="alinha-centro">
                                    <div>Ausente</div>
                                    <div>{{ sessionData.fails.vccAusente }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Invertido</div>
                                    <div>{{ sessionData.fails.vccInvertido }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Em curto</div>
                                    <div>{{ sessionData.fails.vccCurto }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="bloco margem">
                            <div class="alinha-centro titulo fonte-maior">D+</div>
                            <div class="linha" style="justify-content: space-evenly">
                                <div class="alinha-centro">
                                    <div>Ausente</div>
                                    <div>{{ sessionData.fails.dpAusente }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Invertido</div>
                                    <div>{{ sessionData.fails.dpInvertido }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Em curto</div>
                                    <div>{{ sessionData.fails.dpCurto }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="bloco margem">
                            <div class="alinha-centro titulo fonte-maior">D-</div>
                            <div class="linha" style="justify-content: space-evenly">
                                <div class="alinha-centro">
                                    <div>Ausente</div>
                                    <div>{{ sessionData.fails.dmAusente }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Invertido</div>
                                    <div>{{ sessionData.fails.dmInvertido }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Em curto</div>
                                    <div>{{ sessionData.fails.dmCurto }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="bloco margem">
                            <div class="alinha-centro titulo fonte-maior">CC</div>
                            <div class="linha" style="justify-content: space-evenly">
                                <div class="alinha-centro">
                                    <div>Ausente</div>
                                    <div>{{ sessionData.fails.ccAusente }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Invertido</div>
                                    <div>{{ sessionData.fails.ccInvertido }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Em curto</div>
                                    <div>{{ sessionData.fails.ccCurto }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="bloco margem">
                            <div class="alinha-centro titulo fonte-maior">GND</div>
                            <div class="linha" style="justify-content: space-evenly">
                                <div class="alinha-centro">
                                    <div>Ausente</div>
                                    <div>{{ sessionData.fails.gndAusente }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Invertido</div>
                                    <div>{{ sessionData.fails.gndInvertido }}</div>
                                </div>
                                <div class="alinha-centro">
                                    <div>Em curto</div>
                                    <div>{{ sessionData.fails.gndCurto }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="bloco margem">
                            <div class="alinha-centro titulo fonte-maior">Resistor</div>
                            <div class="linha" style="justify-content: space-evenly">
                                <div class="alinha-centro">
                                    <div>Ausente</div>
                                    <div>{{ sessionData.fails.resistorAusente }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <table class="tabela">
                    <tbody v-if="testsOnSession != null">
                        <tr>
                            <th>Tester</th>
                            <th>Giga</th>
                            <th>Aprovado</th>
                            <th>Datahora</th>
                            <th></th>
                        </tr>

                        <tr v-for="ele of testsOnSession.tests" v-bind:key="ele.id">
                            <td>{{ testsOnSession.tester.name ?? "" }}</td>
                            <td>{{ testsOnSession.jig.name ?? "" }}</td>
                            <td><div :class="ele.isApproved ? 'icone-check' : 'icone-cancel'"></div></td>
                            <td>{{ parseDate(ele.datetime, true) ?? "" }}</td>
                            <td><div class="icone-delete" v-on:click="deleteTest(ele.id)"></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import TestesDataSource from "@/data/datasource/TestesDataSource";

const dataSource = new TestesDataSource();

export default {
    components: {},
    data() {
        return {
            ops: [],
            selectedOpId: null,
            injectionOrder: { productName: "" },
            opTestData: {},
            isLoading: null,
            sessionData: null,
            testsOnSession: null,
        };
    },
    methods: {
        parseDate(date, withHour) {
            if (date != null) {
                if (withHour) {
                    date = new Date(date).toLocaleString().replace(",", " -");
                } else {
                    date = new Date(date).toLocaleDateString();
                }
            } else {
                date = "-";
            }
            return date;
        },

        async getOpData(opId) {
            this.clearSelection();
            if (opId == "") return;
            this.isLoading = true;

            this.selectedOpId = opId;
            this.injectionOrder = await dataSource.getOpDetails(opId);
            this.opTestData = await dataSource.getOpTests(opId);

            this.isLoading = false;
        },

        async getGeneralResultForSession(sessionId) {
            this.sessionData = await dataSource.getResultsForSession(sessionId);
            this.testsOnSession = await this.getTestsOnSession(sessionId);
        },

        async getTestsOnSession(sessionId) {
            return await dataSource.getTestsOnSession(sessionId);
        },

        async deleteTest(testId) {
            const testToRemove = this.testsOnSession.tests.find((ele) => ele.id == testId);
            await dataSource.deleteTest(testId);

            this.sessionData.testsDone -= 1;
            this.opTestData.testsDone -= 1;
            this.testsOnSession.testsDone -= 1;

            if (testToRemove.isApproved) {
                this.sessionData.succesfulTests -= 1;
                this.opTestData.succesfulTests -= 1;
                this.testsOnSession.succesfulTests -= 1;
            } else {
                this.sessionData.failedTests -= 1;
                this.opTestData.failedTests -= 1;
                this.testsOnSession.failedTests -= 1;
            }

            const index = this.testsOnSession.tests.indexOf(testToRemove);
            if (index !== -1) {
                this.testsOnSession.tests.splice(index, 1);
            }
        },

        clearSessionData() {
            this.sessionData = null;
        },

        clearSelection() {
            this.selectedOpId = null;
            this.injectionOrder = { productName: "" };
            this.sessionData = null;
            this.testsOnSession = null;
            this.activities = [];
        },
    },
    async created() {
        this.ops = await dataSource.getOps();
    },
};
</script>

<style scoped>
.icone-delete {
    visibility: hidden;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.151);
    border: 1px solid rgba(0, 0, 0, 0.034);
}

tr:hover .icone-delete {
    visibility: visible;
    cursor: pointer;
}
</style>
