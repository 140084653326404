<template>
    <NotificationMessenger ref="notification" :message="notificationMessage" />

    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>Cadastro de parâmetros de teste</h2>
            </div>
        </div>
        <div class="margem container">
            <div class="bloco2 margem" style="display: flex; justify-content: center">
                <fieldset class="grid-2" style="width: 60%">
                    <div class="col-2">
                        <label>Produto</label>
                        <select @change="setProduct($event.target.value)">
                            <option @click="produtoSelecionadoCod = null" value="" selected></option>
                            <option v-for="ele of produtos" v-bind:key="ele.id" :value="ele.code">
                                {{ ele.name }}
                            </option>
                        </select>
                    </div>
                    <div style="grid-row: 2">
                        <label>Tipo</label>
                        <div style="display: flex">
                            <div class="alinha-v">
                                <input type="radio" id="vias-4" :value="false" v-model="tipoVias" name="vias" />
                                <label for="vias-4">4 vias</label>
                            </div>
                            <div class="alinha-v" style="margin-left: 1rem">
                                <input type="radio" id="vias-5" :value="true" v-model="tipoVias" name="vias" />
                                <label for="vias-5">5 vias</label>
                            </div>
                        </div>
                    </div>
                    <div style="grid-row: 2">
                        <label>Possui curto entre D+ e D-?</label>
                        <div style="display: flex">
                            <div class="alinha-v">
                                <input type="radio" id="curto-sim" :value="true" v-model="curto" name="curto" />
                                <label for="curto-sim">Sim</label>
                            </div>
                            <div class="alinha-v" style="margin-left: 1rem">
                                <input type="radio" id="curto-nao" :value="false" v-model="curto" name="curto" />
                                <label for="curto-nao">Não</label>
                            </div>
                        </div>
                    </div>
                    <div style="grid-row: 2">
                        <label>Possui resistor?</label>
                        <div style="display: flex">
                            <div class="alinha-v">
                                <input
                                    type="radio"
                                    id="resistor-sim"
                                    :value="true"
                                    v-model="resistor"
                                    name="resistor"
                                />
                                <label for="resistor-sim">Sim</label>
                            </div>
                            <div class="alinha-v" style="margin-left: 1rem">
                                <input
                                    type="radio"
                                    id="resistor-nao"
                                    :value="false"
                                    v-model="resistor"
                                    name="resistor"
                                />
                                <label for="resistor-nao">Não</label>
                            </div>
                        </div>
                    </div>
                    <div style="grid-row: 2">
                        <button @click="postParam">Salvar</button>
                    </div>
                </fieldset>
            </div>
            <br />
            <div v-if="!isDataLoaded" class="loading"></div>
            <div v-if="isDataLoaded" class="bloco margem">
                <table class="tabela">
                    <tbody>
                        <tr>
                            <th>Produto</th>
                            <th>Vias</th>
                            <th>Curto D+/D-</th>
                            <th>Resistor</th>
                            <th></th>
                        </tr>
                        <tr v-for="ele of produtosCadastrados" v-bind:key="ele.id">
                            <td>{{ ele.product.name }}</td>
                            <td><div>{{ele.is5Wire ? '5' : '4'}}</div></td>
                            <td><div :class="ele.hasShortDpDm ? 'icone-check' : 'icone-cancel'"></div></td>
                            <td><div :class="ele.hasResistor ? 'icone-check' : 'icone-cancel'"></div></td>
                            <td><div class="icone-delete"></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import ParametrosTesteDataSource from "@/data/datasource/ParametrosTesteDataSource";
import NotificationMessenger from "@/components/NotificationMessenger.vue";
import ProdutoTesteModel from "@/data/models/ProdutoTesteModel";

const dataSource = new ParametrosTesteDataSource();

export default {
    components: {
        NotificationMessenger,
    },
    data() {
        return {
            notificationMessage: "",
            produtos: [],
            produtosCadastrados: [],
            produtoSelecionadoCod: null,
            productSelected: null,
            tipoVias: null,
            curto: null,
            resistor: null,
            isDataLoaded: false,
        };
    },
    methods: {
        async postParam() {
            if (
                this.produtoSelecionadoCod == null ||
                this.tipoVias == null ||
                this.curto == null ||
                this.resistor == null
            ) {
                this.notificationMessage = "Preencha todos os campos";
                this.$refs.notification.show();
                return;
            }
            try {
                var response = await dataSource.postParams(
                    this.produtoSelecionadoCod,
                    this.tipoVias,
                    this.curto,
                    this.resistor
                );
                const param = response.data;

                this.produtosCadastrados.push(
                    new ProdutoTesteModel(
                        param.id,
                        param.produto_cod,
                        param.vias == 0 ? true : false,
                        param.curto == 1 ? true : false,
                        param.resistor == 1 ? true : false,
                        { id: this.productSelected.id, name: this.productSelected.name }
                    )
                );

                this.productSelected = null;
                this.produtoSelecionadoCod = null;
                this.curto = null;
                this.tipoVias = null;
                this.resistor = null;
            } catch (e) {
                console.log(e);
                this.notificationMessage = "Ocorreu um erro ao salvar os dados!";
            }

            if (response.msg != null) {
                this.notificationMessage = `Ocorreu um erro ao salvar os dados!\n${response.msg}`;
            } else {
                this.notificationMessage = "Parâmetro salvo com sucesso!";
            }
            this.$refs.notification.show();
        },

        setProduct(prod) {
            this.productSelected = this.produtos.find((ele) => ele.code == prod);

            this.produtoSelecionadoCod = prod;
        },
    },
    async created() {
        this.isDataLoaded = false;
        this.produtos = await dataSource.getProdutos();
        this.produtosCadastrados = await dataSource.getProdutosCadastrados();
        this.isDataLoaded = true;
    },
};
</script>
<style scoped>
td,
th {
    text-align: center !important;
}

label {
    margin: 0px;
}

.icone-delete {
    visibility: hidden;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.151);
    border: 1px solid rgba(0, 0, 0, 0.034);
}

tr:hover .icone-delete {
    visibility: visible;
    cursor: pointer;
}
</style>
