export default class ElectricTestResultModel {
    constructor(testsDone, succesfulTests, failedTests, fails) {
        this.testsDone = testsDone;
        this.succesfulTests = succesfulTests;
        this.failedTests = failedTests;
        this.fails = fails;
    }

    static fromJson(json) {
        return new ElectricTestResultModel(
            json.testes_total,
            json.aprovados,
            (json.tests_total - json.aprovados),
            ElectricTestFails.fromJson(json.falhas),
        );
    }
}

class ElectricTestFails {
    constructor(
        vccInvertido,
        vccAusente,
        vccCurto,
        dpInvertido,
        dpAusente,
        dpCurto,
        dmInvertido,
        dmAusente,
        dmCurto,
        ccInvertido,
        ccAusente,
        ccCurto,
        gndInvertido,
        gndAusente,
        gndCurto,
        resistorAusente,
    ) {
        this.vccInvertido = vccInvertido;
        this.vccAusente = vccAusente;
        this.vccCurto = vccCurto;
        this.dpInvertido = dpInvertido;
        this.dpAusente = dpAusente;
        this.dpCurto = dpCurto;
        this.dmInvertido = dmInvertido;
        this.dmAusente = dmAusente;
        this.dmCurto = dmCurto;
        this.ccInvertido = ccInvertido;
        this.ccAusente = ccAusente;
        this.ccCurto = ccCurto;
        this.gndInvertido = gndInvertido;
        this.gndAusente = gndAusente;
        this.gndCurto = gndCurto;
        this.resistorAusente = resistorAusente;
    }

    static fromJson(json) {
        return new ElectricTestFails(
            json.vcc_invertido,
            json.vcc_ausente,
            json.vcc_curto,
            json.dp_invertido,
            json.dp_ausente,
            json.dp_curto,
            json.dm_invertido,
            json.dm_ausente,
            json.dm_curto,
            json.cc_invertido,
            json.cc_ausente,
            json.cc_curto,
            json.gnd_invertido,
            json.gnd_ausente,
            json.gnd_curto,
            json.resistorAusente ?? 0
        );
    }
}
