import { api } from "roboflex-thalamus-request-handler/dist";
import { ProductModel } from "../models/ProductModel";
import ProdutoTesteModel from "../models/ProdutoTesteModel";

export default class ParametrosTesteDataSource {
    /// Lista todos os produtos
    async getProdutos() {
        const params = {params: {
            familia: [2,3,4,7,8,14]
        }}
        var rawData = await api.get("produto-filtrar", params);
        var productList = [];

        rawData.data.forEach((element) => {
            productList.push(ProductModel.fromJson(element));
        });

        return productList;
    }

    /// Enviar ao backend os parâmetros de teste de um determinado produto
    async postParams(codigo, is5wire, hasShort, hasResistor) {
        var content = {
            produto_cod: parseInt(codigo),
            vias: is5wire == true ? 1 : 0,
            curto: hasShort == true ? 1 : 0,
            resistor: hasResistor == true ? 1 : 0,
        };

        var response = await api.post("parametroTeste", content);
        return response;
    }

    /// Retorna todos os produtos com parâmetros de teste já cadastrados
    async getProdutosCadastrados() {
        var rawData = await api.get("parametroTeste");
        var productList = [];
        rawData.data.forEach((element) => {
            productList.push(ProdutoTesteModel.fromJson(element));
        });


        return productList;
    }
}
