export class ProductModel {
    constructor(
        id,
        name,
        code,
    ){
        this.id = id;
        this.name = name;
        this.code = code;
    }

    static fromJson(json){
        return new ProductModel(
            json.id,
            json.desc,
            json.produto_cod,
        )
    }
}