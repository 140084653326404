import JigModel from "./JigModel";
import PersonModel from "./PersonModel";

export class ElectricTestSessionModel {
    constructor(id, startDt, endDt, testsDone, succesfulTests, failedTests, tester, jig) {
        this.id = id;
        this.startDt = startDt;
        this.endDt = endDt;
        this.testsDone = testsDone;
        this.succesfulTests = succesfulTests;
        this.failedTests = failedTests;
        this.tester = tester;
        this.jig = jig;
    }

    static fromJson(json){
        return new ElectricTestSessionModel(
            json.id,
            json.dt_inicio,
            json.dt_fim,
            json.testes_total,
            json.aprovados,
            (json.testes_total - json.aprovados),
            PersonModel.fromJson(json.operador),
            JigModel.fromJson(json.giga),
        )
    }
}
