import JigModel from "./JigModel";
import PersonModel from "./PersonModel";

export default class ElectricTestModel {
    constructor(
        tester,
        jig,
        tests,
    ){
        
        this.tester = tester;
        this.jig = jig;
        this.tests = tests;
    }

    static fromJson(json){
        return new ElectricTestModel(
            PersonModel.fromJson(json.operador),
            JigModel.fromJson(json.giga),
            json.testes.map((e)=> SingleTestModel.fromJson(e)),
        )
    }
}

class SingleTestModel {
    constructor(id, isApproved, datetime){
        this.id = id;
        this.isApproved = isApproved;
        this.datetime = datetime;
    }

    static fromJson(json){
        return new SingleTestModel(
            json.id,
            json.aprovado,
            json.created_at,
        )
    }
}