import { api } from "roboflex-thalamus-request-handler/dist";
import { InjectionOrderModel } from "../models/InjectionOrderModel";
import OpTestModel from "../models/OpTestModel";
import ElectricTestResultModel from "../models/ElectricTestResultModel";
import ElectricTestModel from "../models/ElectricTestModel";

export default class TestesDataSource {
    async getOps() {
        let response = await api.get("ordem-producao/injecao/existente");
        let convertedData = [];

        for (var ele in response.data) {
            convertedData.push(InjectionOrderModel.fromJson(response.data[ele]));
        }
        return convertedData;
    }

    async getOpDetails(opId) {
        let response = await api.get(`ordem-producao/injecao/buscar/${opId}`);
        let convertedData = InjectionOrderModel.fromJson(response.data);
        return convertedData;
    }

    async getOpTests(opId) {
        let response = await api.get(`/teste/resultados/op/${opId}`);
        let convertedData;

        convertedData = OpTestModel.fromJson(response.data);

        return convertedData;
    }

    async getResultsForSession(sessionId) {
        let response = await api.get(`/sessao/${sessionId}/resultados`);
        let convertedData = ElectricTestResultModel.fromJson(response.data);

        return convertedData;
    }

    async getTestsOnSession(sessionId) {
        let response = await api.get(`/sessao/${sessionId}/testes`);
        let convertedData;

        console.log(response.data);

        convertedData = ElectricTestModel.fromJson(response.data);

        return convertedData;
    }

    async deleteTest(testId){
        let response = await api.delete(`/teste/${testId}`);
        console.log(response)
        
    }
}
