export default class JigModel{
    constructor(id, name, mac){
        this.id = id;
        this.name = name;
        this.mac = mac;
    }

    static fromJson(json){
        return new JigModel(
            json.id,
            json.nome,
            json.mac,
        );
    }
}