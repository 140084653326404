import { ElectricTestSessionModel } from "./ElectricTestSessionModel";

export default class OpTestModel {
    constructor(testsDone, succesfulTests, failedTests, sessions) {
        this.testsDone = testsDone;
        this.succesfulTests = succesfulTests;
        this.failedTests = failedTests;
        this.sessions = sessions;
    }

    static fromJson(json){
        return new OpTestModel(
            json.testes_total,
            json.aprovados,
            (json.testes_total && json.aprovados) != null ? (json.testes_total - json.aprovados) : null,
            Array.isArray(json.sessoes)
            ? json.sessoes.map((e) => ElectricTestSessionModel.fromJson(e))
            : [] 
        )
    }
}
