export default class PersonModel {
    constructor(id, name){
        this.id = id,
        this.name = name;
    }

    static fromJson(json){
        return new PersonModel(
            json.id,
            json.nomeCompleto ?? json.nome,
        )
    }
}